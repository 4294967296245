import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Loader from 'react-loader-spinner'
import Select from 'react-select'

import { useAuth0 } from 'utils/react-auth0-spa'
import { getAuth0LockLangCode } from 'utils/auth0LockLangCode'
import UserContext from 'context/user/UserContext'
import logo from 'images/png/fwaLogo.png'
import logoSmall from 'images/png/fwaLogoSmall.png'
import useWindowDimensions from 'context/windowDimensions'

import {
  NavbarContainer,
  NavBarCenter,
  NavLogo,
  NavLinks,
  NavLinksSmallView,
  NavButtonDiv,
  LanguageSelect,
  User,
  SignInButton,
  SignedInDiv,
  AccountButton,
  LoaderWrapperSignIn,
  SignInOutContainer,
  NavBarTop,
  NavBarBottom,
} from 'components/styleComponents/HeaderStyles'

const Header = () => {
  const LANGUAGE_CODE_ENGLISH = 'en'
  const LANGUAGE_CODE_SPANISH = 'es'
  const LANGUAGE_ENGLISH_LABEL = 'English'
  const LANGUAGE_SPANISH_LABEL = 'Español'

  const {
    isAuthenticated,
    loginWithRedirect,
    auth0User,
    auth0Loading,
  } = useAuth0()
  const { username, fetchUserFromFirestore, userStateLoading } = useContext(
    UserContext,
  )
  const { t, i18n, ready } = useTranslation('header')

  const { width } = useWindowDimensions()

  const options = [
    { value: LANGUAGE_CODE_ENGLISH, label: LANGUAGE_ENGLISH_LABEL },
    { value: LANGUAGE_CODE_SPANISH, label: LANGUAGE_SPANISH_LABEL },
  ]

  // this function is used in the state variable "selectedLanguageLabel" as the initilized value.
  const initializeSelectedLanguageLabel = () => {
    try {
      return options.find((opt) => opt.value === selectedLanguage).label
    } catch (error) {
      return
    }
  }

  // state variables to track the selected language
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
  const [selectedLanguageLabel, setSelectedLanguageLabel] = useState(
    initializeSelectedLanguageLabel(),
  )

  // when user is authenticated, fetch the user from DB
  useEffect(() => {
    if (auth0User && username === '') {
      fetchUserFromFirestore()
    }
    // eslint-disable-next-line
  }, [auth0User])

  // change the language whenever a user selects a new dropdown option from the language select menu.
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
  }, [selectedLanguage, i18n])

  const handleChange = (lang) => {
    setSelectedLanguage(lang.value)
    setSelectedLanguageLabel(lang.label)
  }

  // this is a config option for the react-select box for the language dropdown. These configs are what set the stylings for certain parts of the select box.
  const selectStyles = {
    control: () => ({
      display: 'flex',
      border: '1px #707070 solid',
      borderRadius: '5px',
      height: '2.5em',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: width && width >= 650 ? '100px' : '50px',
    }),
  }

  // Const JSX variables to be used in the render method
  const loadingIndicator = (
    <LoaderWrapperSignIn>
      <Loader type="ThreeDots" color="#4A90E2" height={50} width={50} />
    </LoaderWrapperSignIn>
  )

  const signInButton = (
    <SignInButton>
      <button
        onClick={() =>
          loginWithRedirect({
            redirect_uri:
              typeof window !== 'undefined'
                ? window.location.href
                : process.env.GATSBY_WEBSITE_URL,
            ui_locales: getAuth0LockLangCode(i18n.language),
          })
        }
      >
        {t('signIn')}
      </button>
    </SignInButton>
  )

  const signedInButton = (
    <SignedInDiv>
      <User>
        {t('greeting')} {username}
      </User>
      <AccountButton>
        <Link to="/account">
          <button>{t('account')}</button>
        </Link>
      </AccountButton>
    </SignedInDiv>
  )

  return (
    <NavbarContainer>
      <NavBarTop>
        <NavBarCenter>
          <NavLogo>
            <Link to="/">
              <img
                src={width && width >= 550 ? logo : logoSmall}
                alt="academy.fluentworlds.com"
                id="logo"
              />
            </Link>
          </NavLogo>
          {width && width <= 850 ? null : (
            <NavLinks>
              <NavButtonDiv>
                <Link to="/courses">{t('courses')}</Link>
              </NavButtonDiv>
              <NavButtonDiv>
                <Link to="/how-it-works">{t('howItWorks')}</Link>
              </NavButtonDiv>
              <NavButtonDiv>
                <Link to="/enterprise">{t('enterprise')}</Link>
              </NavButtonDiv>
            </NavLinks>
          )}
          <LanguageSelect>
            <Select
              styles={selectStyles}
              value={selectedLanguage}
              placeholder={selectedLanguageLabel}
              isLoading={!ready}
              onChange={handleChange}
              options={options}
            />
          </LanguageSelect>
          <SignInOutContainer>
            {/* show the loading animation if were still in the process of logging in/checking login status. */}
            {auth0Loading || userStateLoading || !ready ? (
              loadingIndicator
            ) : (
              // Otherwise show the main components, which will be different if the user is logged in vs not logged in.
              <>{!isAuthenticated ? signInButton : signedInButton}</>
            )}
          </SignInOutContainer>
        </NavBarCenter>
      </NavBarTop>
      <NavBarBottom>
        {width && width >= 850 ? null : (
          <NavLinksSmallView>
            <NavButtonDiv>
              <Link to="/courses">{t('courses')}</Link>
            </NavButtonDiv>
            <NavButtonDiv>
              <Link to="/how-it-works">{t('howItWorks')}</Link>
            </NavButtonDiv>
            <NavButtonDiv>
              <Link to="/enterprise">{t('enterprise')}</Link>
            </NavButtonDiv>
          </NavLinksSmallView>
        )}
      </NavBarBottom>
    </NavbarContainer>
  )
}

export default Header
