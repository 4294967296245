import React from 'react'
import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa'

const SocialIcons = [
  {
    icon: <FaFacebookF />,
    url: 'https://www.facebook.com/fluentworlds',
  },
  {
    icon: <FaInstagram />,
    url: 'https://www.instagram.com/fluentworlds/',
  },
  {
    icon: <FaYoutube />,
    url: 'https://www.youtube.com/fluentworlds',
  },
  {
    icon: <FaTwitter />,
    url: 'https://twitter.com/fluentworlds3D',
  },
]

export default SocialIcons
