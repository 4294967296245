import styled from '@emotion/styled'

export const NavbarContainer = styled.nav`
  height: 6.5em;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1000px) {
    font-size: 0.75em;
  }

  @media (max-width: 850px) {
    height: 8em;
  }

  @media (max-width: 650px) {
    font-size: 0.8em;
  }
`

export const NavBarTop = styled.div`
  width: 85%;

  @media (max-width: 850px) {
    height: 4em;
  }
`

export const NavBarBottom = styled.div`
  @media (max-width: 850px) {
    width: 85%;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const NavLinksSmallView = styled.div`
  @media (max-width: 850px) {
    height: 4em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`

export const NavBarCenter = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 1;
`

export const NavLogo = styled.div`
  height: 6.5em;
  display: flex;
  align-items: center;
  margin-right: auto;

  a {
    img {
      height: 6em;
    }
  }

  @media (max-width: 1300px) {
    a {
      img {
        height: 5em;
      }
    }
  }

  @media (max-width: 1200px) {
    a {
      img {
        height: 4em;
      }
    }
  }

  @media (max-width: 1100px) {
    height: 4em;
    a {
      img {
        height: 3em;
      }
    }
  }
`

export const NavLinks = styled.div`
  height: 6.5em;
  display: flex;
  align-items: center;

  @media (max-width: 850px) {
    height: 4em;
  }
`

export const NavButtonDiv = styled.div`
  margin-right: 20px;
`

export const LanguageSelect = styled.div`
  height: 6.5em;
  display: flex;
  align-items: center;
  margin-right: 30px;

  @media (max-width: 850px) {
    height: 4em;
  }
`

export const SignInOutContainer = styled.div`
  height: 6.5em;
  display: flex;
  align-items: center;

  @media (max-width: 850px) {
    height: 4em;
  }
`

export const SignInButton = styled.div`
  button {
    background-color: #fd7603;
    border: none;
    padding: 5px 25px;
    cursor: pointer;
  }

  @media (max-width: 650px) {
    button {
      font-size: 1em;
    }
  }
`

export const SignedInDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AccountButton = styled.div`
  button {
    background-color: #fd7603;
    border: none;
    padding: 5px 25px;
    cursor: pointer;
  }

  @media (max-width: 650px) {
    button {
      font-size: 1em;
    }
  }
`

export const LoaderWrapperSignIn = styled.div`
  background-color: transparent;
  border: none;
`

export const User = styled.span`
  font-weight: bold;
`
