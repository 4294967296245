import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import socialIcons from 'components/General/SocialIcons'
import mq from 'components/styleComponents/breakpoints'

const Footer = () => {
  const { t, ready } = useTranslation('footer')

  const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #000000;
    color: white;
    width: 100%;
    font-size: 2.5rem;
    font-family: 'arial';
    padding-left: 15%;
    padding-top: 8rem;
    padding-bottom: 8rem;
    margin-top: auto;

    ${mq['sm']} {
      font-size: 1.5rem;
      padding-left: 2rem;
      padding-bottom: 4rem;
      padding-top: 4rem;
      border: none;
    }
  `

  const SocialMediaIconsContainer = styled.div`
    font-size: 2.7rem;
    margin-bottom: 3rem;

    a {
      color: white;
      text-decoration: none;
      cursor: pointer;
      margin-right: 2.5rem;

      &:hover {
        color: #0080fa;
        transition: 0.2s;
      }
    }
  `

  const CopyrightTextContainer = styled.div``

  return !ready ? null : (
    <FooterContainer>
      <SocialMediaIconsContainer>
        {socialIcons.map((item, index) => {
          return (
            <a
              key={index}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          )
        })}
      </SocialMediaIconsContainer>
      <CopyrightTextContainer>
        © {new Date().getFullYear()} {t('footerText')}
      </CopyrightTextContainer>
    </FooterContainer>
  )
}

export default Footer
