import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Global } from '@emotion/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import GlobalStyles from './styleComponents/GlobalStyles'
import Header from 'components/Header'
import Footer from 'components/Footer'

const Layout = ({ children, headerBackgroundColor, showPipedrive }) => {
  const PIPEDRIVE_FILENAME =
    'https://leadbooster-chat.pipedrive.com/assets/loader.js'
  const PIPEDRIVE_IFRAME_ID = 'LeadboosterContainer'

  const { t, i18n, ready } = useTranslation('layout')

  const PIPEDRIVE_UUIDS = Object.freeze({
    en: process.env.GATSBY_ENGLISH_CHATBOT_PIPEDRIVE_UUID,
    es: process.env.GATSBY_SPANISH_CHATBOT_PIPEDRIVE_UUID,
  })

  // if showPipedrive is false, remove Pipedrive script and iframe from the page
  useEffect(() => {
    if (!showPipedrive) {
      const tags = document.getElementsByTagName('script')

      // remove pipedrive script from the DOM
      for (let i = tags.length; i >= 0; i--) {
        // search backwards within nodelist for matching elements to remove
        if (tags[i] && tags[i].getAttribute('src') === PIPEDRIVE_FILENAME) {
          tags[i].parentNode.removeChild(tags[i])
        }
      }

      // remove iframe from the DOM the iframes may not be loaded in immediately so if this becomes an issue
      // we can look into an alternative solution
      let calendlyIframe = document.getElementById(PIPEDRIVE_IFRAME_ID)

      do {
        if (calendlyIframe) {
          calendlyIframe.parentNode.removeChild(calendlyIframe)
        }

        calendlyIframe = document.getElementById(PIPEDRIVE_IFRAME_ID)
      } while (calendlyIframe !== null)

      delete window.LeadBooster
    }
  }, [showPipedrive])

  const updatePipedrive = (pipedriveUUID) => {
    if (typeof window !== 'undefined') {
      window.pipedriveLeadboosterConfig = {
        base: 'leadbooster-chat.pipedrive.com',
        companyId: 7452276,
        playbookUuid: pipedriveUUID,
        version: 2,
      }
      ;(function () {
        var w = window
        if (w.LeadBooster) {
          w.LeadBooster = {
            q: [],
            on: function (n, h) {
              this.q.push({ t: 'o', n: n, h: h })
            },
            trigger: function (n) {
              this.q.push({ t: 't', n: n })
            },
          }
        }
      })()
    }
  }

  return (
    <>
      <Global styles={GlobalStyles} />
      {!ready ? null : (
        <Helmet>
          <title>{t('title')}</title>
          {showPipedrive && (
            <script
              src="https://leadbooster-chat.pipedrive.com/assets/loader.js"
              type="text/javascript"
              async
            />
          )}
          {showPipedrive && i18n.language && PIPEDRIVE_UUIDS && (
            <script type="text/javascript">
              {updatePipedrive(PIPEDRIVE_UUIDS[i18n.language])}
            </script>
          )}
        </Helmet>
      )}
      <>
        <ToastContainer
          limit={1}
          position="bottom-left"
          autoClose={8000}
          hideProgressBar={true}
        />
        <Header headerBackgroundColor={headerBackgroundColor} />
        <main>{children}</main>
        <Footer />
      </>
    </>
  )
}

Layout.defaultProps = {
  headerBackgroundColor: undefined,
  showPipedrive: true,
}

Layout.propTypes = {
  headerBackgroundColor: PropTypes.string,
  showPipedrive: PropTypes.bool,
}

export default Layout
